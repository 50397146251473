import {
    createAsyncThunk,
    Action,
    PayloadAction
  } from "@reduxjs/toolkit";

  import { FetchError, fetchAsync } from "../fetch";
  import { ReducerState } from "./types";
  
  export function getActionParams<T>(action: any): T {
    return action.meta.arg as T;
  }
  
  export function basicFetchCancelString(options: {actionName:string;actionPrefix:string}){
      return `${options.actionPrefix}_${options.actionName}`;
  }

  //export this from here as well to make imports easier
  export { FetchError };
  export type { ReducerState };

  type Props<RETURN, QUERYPARAMS, STATE> = {
    /**
     * @param actionPrefix Usually the name of the duck. Should be the same for all actions in this file.
     */
    actionPrefix: string;
    /**
     * @param actionName Name of the action.
     */
    actionName: string;
    /**
     * @param url URL to call.
     */
    url: string;
    /**
     * This will be called when the request is created.
     */
    pending: (
      state: STATE,
      action: Action & { params: QUERYPARAMS }
    ) => STATE | void;
    /**
     * This is called when the request completes.
     */
    fulfilled: (
      state: STATE,
      action: PayloadAction<RETURN> & { params: QUERYPARAMS }
    ) => STATE | void;
    /**
     * This is called if the request errors
     */
    rejected: (
      state: STATE,
      action: PayloadAction<FetchError> & { params: QUERYPARAMS }
    ) => STATE | void;
    /**
     * This is called when the request complets, and also allows you to dispatch calls.
     */
  
    onComplete?: (
      payload: { data: RETURN | FetchError; params: QUERYPARAMS },
      dispatch: any
    ) => void;
    /**
     * Allows you to modify the parameters being passed to the URL
     * @returns entire object to be passed to the URL
     */
    transformQueryParams?: (params: QUERYPARAMS) => object;
    /**
     * Allows you to modify the url being used (i.e. if page doesnt accept post. https://site.com/page/5)
     * @returns completely formatted string
     */
    transformUrl?: (url: string, params: QUERYPARAMS) => string;
    /**
     * Required if you are using authentication and require a bearer token.
     * This will add the Authorizion: Bearer to the headers of the call
     * @returns generated token
     */
    getAuthBearerToken?: () => Promise<{ token: string } | FetchError>;
    /**
     * This will allow you to have cancellable calls. If 2 calls have the same cancel string,
     * the first run one will be cancelled.
     * @returns string that is unique to the call.
     */
    getFetchCancelString?: (options: {
      actionName: string;
      actionPrefix: string;
      params: QUERYPARAMS;
    }) => string | undefined;
  };
  
/**
  * Create a redux action. The action will appear in redux as 'actionPrefix/actionName'
  * @type RETURN The return type of the url you are calling
  * @type QUERYPARAMS The parameters for this action. These are sent to the URL by default
  * @type STATE The state that will be passed into this objects functions.
*/
  export function createAsyncAction<
    RETURN extends object,
    QUERYPARAMS extends object | undefined,
    STATE extends object
  >(props: Props<RETURN,QUERYPARAMS,STATE>) {
    const { pending, fulfilled, rejected } = props;
  
    var action = createAsyncActionBase<RETURN, QUERYPARAMS>({
      actionName: props.actionName,
      actionPrefix: props.actionPrefix,
      url: props.url,
      getAuthBearerToken: props.getAuthBearerToken,
      onComplete: props.onComplete,
      transformQueryParams: props.transformQueryParams,
      transformUrl: props.transformUrl,
      getFetchCancelString: props.getFetchCancelString
    });
  
    return {
      action,
      reducer: {
        [action.pending.type]: (
          state: STATE,
          action: PayloadAction<{ params: QUERYPARAMS }>
        ) => {
          var params = getActionParams<QUERYPARAMS>(action);
          var ret = {
            ...action,
            params,
          };
          return pending(state, ret);
        },
        [action.fulfilled.type]: (
          state: STATE,
          action: PayloadAction<{ data: RETURN; params: QUERYPARAMS }>
        ) => {
          var ret = {
            ...action,
            params: action.payload?.params,
            payload: action.payload?.data,
          };
          return fulfilled(state, ret);
        },
        [action.rejected.type]: (
          state: STATE,
          action: PayloadAction<{ data: FetchError; params: QUERYPARAMS }>
        ) => {
          var ret = {
            ...action,
            params: action.payload?.params,
            payload: action.payload?.data,
          };
          return rejected(state, ret);
        },
      },
      actionName: props.actionName,
    };
  }
  
  function createAsyncActionBase<
    RETURN extends object,
    QUERYPARAMS extends (object & { urlOverride?: string }) | undefined
  >(props: {
    actionPrefix: string;
    actionName: string;
    url: string;
    onComplete?: (
      payload: { data: RETURN | FetchError; params: QUERYPARAMS },
      dispatch: any
    ) => void;
    transformQueryParams?: (params: QUERYPARAMS) => object;
    transformUrl?: (url: string, params: QUERYPARAMS) => string;
    getAuthBearerToken?: ()=>Promise<{token: string} | FetchError>;
    getFetchCancelString?: (options: {actionName:string;actionPrefix:string,params: QUERYPARAMS})=>string | undefined;
  }) {
    const {
      actionPrefix,
      actionName,
      url,
      onComplete,
      transformQueryParams,
      transformUrl,
      getAuthBearerToken,
      getFetchCancelString
    } = props;
  
    var itemUrl = url;
  
    return createAsyncThunk(
      `${actionPrefix}/${actionName}`,
      async (params: QUERYPARAMS, ThunkApi) => {
        const { rejectWithValue } = ThunkApi;
  
        if (params?.urlOverride) {
          itemUrl = params.urlOverride;
          delete params.urlOverride;
        }

        console.log(params);
        
  
        if (transformUrl) {
          itemUrl = transformUrl(url, params);
        }
  
        var queryParams = transformQueryParams
          ? { ...transformQueryParams(params) }
          : { ...params };
  
        var fetchOptions: RequestInit = {};
  
        if (getAuthBearerToken) {
          var authValue = await getAuthBearerToken();

          if (authValue instanceof FetchError) {
            return rejectWithValue({
              params,
              data: authValue,
            });
          } else {
            fetchOptions.headers = { Authorization: `Bearer ${authValue.token}`};
          }
        }

        var cancelString = !getFetchCancelString ? undefined : getFetchCancelString({actionPrefix, actionName,params});
  
        var ret = {
          data: await fetchAsync<RETURN>(itemUrl, queryParams, fetchOptions,cancelString),
          params,
        };
  
        if (ret.data instanceof FetchError) {
          if (onComplete) {
            onComplete(ret, ThunkApi.dispatch);
          }
          return rejectWithValue({ ...ret });
        } else {
          if (onComplete) {
            onComplete(ret, ThunkApi.dispatch);
          }
  
          return ret;
        }
      },
      { dispatchConditionRejection: true }
    );
  }
  
  